/**
 * Easings object.
 */

const Easing = {

	calc(value, type, curve, strength = 1) {
		switch (type) {
			default:
			case Easing.In:
				return get(value, curve, strength)

			case Easing.Out:
				return 1 - get(1 - value, curve, strength)

			case Easing.InOut:
				if (value <= 0.5) {
					return get(value * 2, curve, strength) * 0.5
				}
				else {
					return 1 - get((1 - value) * 2, curve, strength) * 0.5
				}

			case Easing.OutIn:
				return value
		}
	},

	// Types
	In: 0,
	Out: 1,
	InOut: 2,
	OutIn: 3,

	// Curves
	Linear: 0,
	Quad: 1,
	Cubic: 2,
	Quart: 3,
	Quint: 4,
	Expo: 5,
	Circ: 6,
	Sine: 7,
	Bounce: 8,
	Back: 9,
	Elastic: 10,

	// Named curves for CSS.
	cssCurves: {
		// Cheers to easings.net
		linear: 'linear',
		inSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
		outSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
		inOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
		inQuad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
		outQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
		inOutQuad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
		inCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
		outCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
		inOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
		inQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
		outQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
		inOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
		inQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
		outQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
		inOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
		inExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
		outExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
		inOutExpo: 'cubic-bezier(1, 0, 0, 1)',
		inCircle: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
		outCircle: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
		inOutCircle: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
		inBack: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
		outBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
		inOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
	}

}

function get(value, ease, strength) {
	switch (ease) {
		case Easing.Quad:
			return Math.pow(value, 2)

		case Easing.Cubic:
			return Math.pow(value, 3)

		case Easing.Quart:
			return Math.pow(value, 4)

		case Easing.Quint:
			return Math.pow(value, 5)

		case Easing.Expo:
			return Math.pow(2, 8 * (value - 1))

		case Easing.Circ:
			return 1 - Math.sin(Math.acos(value))

		case Easing.Sine:
			return 1 - Math.sin((1 - value) * Math.PI / 2)

		case Easing.Bounce: {
			let a, b, give = 0

			for (a = 0, b = 1; true; a += b, b/= 2){
				if (value >= (7 - 4 * a) / 11) {
					give = b * b - Math.pow((11 - 6 * a - 11 * value) / 4, 2)
					break
				}
			}

			return give
		}

		case Easing.Back:
			return Math.pow(value, 2) * ( (strength + 1) * value - strength)

		case Easing.Elastic:
			return Math.pow(2, 10 * --value ) * Math.cos(20 * value * Math.PI * strength / 3)

		default:
			return value
	}
}

export default Easing