export default function initializeEventsBlocks() {
    const eventsblocks = [...document.querySelectorAll('div.eventsblock')]
    if (eventsblocks.length <= 0) return

    for (let i = 0; i < eventsblocks.length; i++) {
        const eventsblock = eventsblocks[i]
        const rawJson = eventsblock.querySelector('#json').dataset.json

        initializeEventsBlock(eventsblock, JSON.parse(rawJson))
    }
}

function initializeEventsBlock(eventsblock, json) {
    const select = eventsblock.querySelector('#filter')

    onSelect({ target: { value: 'new-old' } }, eventsblock, json)
    
    select.addEventListener('change', (e) => onSelect(e, eventsblock, json))
}

function onSelect(event, eventsblock, json) {
    const selected = event.target.value

    switch(selected) {
        case 'new-old':
            setSelected({ eventsblock, title: 'Datum nieuw naar oud' })

            fill(
                eventsblock, 
                json.sort((a, b) => new Date(b.date.replace(' ', 'T')).getTime() - new Date(a.date.replace(' ', 'T')).getTime())
            )
            break
        case 'old-new':
            setSelected({ eventsblock, title: 'Datum oud naar nieuw' })
            
            fill(
                eventsblock,
                json.sort((b, a) => new Date(b.date.replace(' ', 'T')).getTime() - new Date(a.date.replace(' ', 'T')).getTime())
            )
            break
        case 'a-z':
            setSelected({ eventsblock, title: 'Alfabet A-Z' })

            fill(
                eventsblock,
                json.sort((a ,b) => {
                    var a1 = a.title.toLowerCase();
                    var b1 = b.title.toLowerCase();
                    return a1<b1 ?-1:a1> b1? 1 :0;
                })
            )
            break
        case 'z-a':
            setSelected({ eventsblock, title: 'Alfabet Z-A' })

            fill(
                eventsblock,
                json.sort((b, a) => {
                    var a1 = a.title.toLowerCase();
                    var b1 = b.title.toLowerCase();
                    return a1<b1 ?-1:a1> b1? 1 :0;
                })
            )
            break
        default:
            break
    }
}

function fill(eventsblock, json) {
    const lang = document.querySelector('html').getAttribute('lang') || 'nl'

    const container = eventsblock.querySelector('div.event-container')
    container.innerHTML = ''

    const formatTime = (date) => {
        const doubleDigit = (val) => val < 10 ? `0${val}` : `${val}`
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes()
        return doubleDigit(hours) + ':' + doubleDigit(minutes)
    }

    for (let i = 0; i < json.length; i++) {
        const event = json[i]
        const eventItem = document.createElement('a')
        eventItem.classList.add('eventitem')
        eventItem.href = (location.pathname + '/' + event.alias).replace(/\/{2,}/g, '/')
        const preFormat = new Date(event.date.replace(' ', 'T') + 'Z')
        const nlFormat = new Intl.DateTimeFormat(lang, { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }) 
        const [{ value: day },, { value: month },, { value: year}] = nlFormat.formatToParts(preFormat)

        const date = `${day} ${month} ${year}`
        const time = formatTime(preFormat)

        eventItem.appendChild(DateBox({ date, time }))
        eventItem.appendChild(TextBox({ text: event.title }))
        eventItem.appendChild(Button())
        
        container.appendChild(eventItem)
    }
}

// Bit React esque components
const DateBox = (props) => {
    const datebox = document.createElement('div')
    const span1 = document.createElement('span')
    const span2 = document.createElement('span')
    datebox.classList.add('date-box')
    span1.textContent = props.date
    span2.textContent = props.time + ' uur'
    datebox.appendChild(span1)
    datebox.appendChild(span2)
    return datebox
}

const TextBox = (props) => {
    const textbox = document.createElement('div')
    const span = document.createElement('span')
    textbox.classList.add('text-box')
    span.innerHTML = props.text
    textbox.appendChild(span)
    return textbox
}

const Button = () => {
    const button = document.createElement('div')
    const span = document.createElement('span')
    button.classList.add('button-box')
    span.innerHTML = siteTranslations.aanmelden
    button.appendChild(span)
    return button
}

const setSelected = (props) => {
    const selected = props.eventsblock.querySelector('#selected-filter')
    const span = selected.querySelector('span')
    span.innerHTML = props.title
}

const giveFormattedTime = (date) => {
    const doubleDigit = (val) => val <= 9 ? `0${val}` : `${val}`
    const hours = date.getHours()
    const formatted = (hours >= 2) ? hours - 2 : 24 - (2 - hours)
    return `${doubleDigit(formatted)}:${doubleDigit(date.getMinutes())}`
}
