/**
 * Code specifically for the Spiegelapp page.
 */
export default function initializeSpiegelapp() {
	if (!document.body.classList.contains('spiegelapp')) {
		return
	}

	initializeCollapsibles()

	new TabCarousel(document.querySelector('#our-expertise'), 'our-expertise-page')
	new TabCarousel(document.querySelector('#treatments'), 'treatment')
}

class TabCarousel {
	constructor(parent, pageClassName) {
		this.parent = parent
		this.parentContent = this.parent.querySelector('content')
		this.parentTextblock = this.parentContent.querySelector('div.textblock')

		// Tabs.
		this.tabs = []
		this.tabsHolder = document.createElement('div')
		this.tabsHolder.classList.add('tabs-holder')
		this.parentTextblock.appendChild(this.tabsHolder)

		// Pages holder.
		this.pages = []
		this.pagesHolder = document.createElement('div')
		this.pagesHolder.classList.add('pages-holder')
		this.parentContent.appendChild(this.pagesHolder)

		// Move all the sections/content.
		const pages = document.querySelectorAll('section.' + pageClassName)
		for (let a = 0; a < pages.length; a++) {
			const page = pages[a]

			const textblock = page.querySelector('content > *')
			if (textblock) {
				page.parentNode.removeChild(page)
				this.pages.push(textblock)

				textblock.classList.add('carousel-page')

				// Tab.
				const title = textblock.querySelector('h2')
				const tab = document.createElement('a')
				const span = document.createElement('span')
				span.textContent = title.textContent.trim()
				title.parentNode.removeChild(title)
				tab.appendChild(span)

				tab.addEventListener('click', this.onTabClick(tab))
				tab.classList.add('tab')
				this.tabs.push(tab)
			}
		}

		this.tabs.map(tab => this.tabsHolder.appendChild(tab))
		this.pages.map(page => this.pagesHolder.appendChild(page))

		this.onTabClick(this.tabs[0])()

		addEventListener('resize', this.onResize)
		addEventListener('load', this.onResize)
	}

	onTabClick = (thisTab) => () => {
		const index = this.tabs.indexOf(thisTab)
		if (index < 0) {
			return
		}

		for (let a = 0; a < this.tabs.length; a++) {
			if (a == index) {
				this.tabs[a].classList.add('active')
				this.pages[a].classList.add('show')
			}
			else {
				this.tabs[a].classList.remove('active')
				this.pages[a].classList.remove('show')
			}

			// Special class for the tab preceding the currently selected.
			if (a == index - 1) {
				this.tabs[a].classList.add('preceding-active')
			}
			else {
				this.tabs[a].classList.remove('preceding-active')
			}
		}

		this.onResize()
	}

	onResize = () => {
		for (const page of this.pages) {
			if (page.classList.contains('show')) {
				this.pagesHolder.style.height = page.offsetHeight + 'px'
				break
			}
		}
	}
}

function initializeCollapsibles() {
	const textblocks = document.querySelectorAll('section.samenwerken div.textblock:nth-child(2)')
	for (let a = 0; a < textblocks.length; a++) {
		if (textblocks[a].childElementCount > 1) {
			const moreButton = document.createElement('a')
			moreButton.classList.add('more')
			moreButton.textContent = siteTranslations.options
			moreButton.addEventListener('click', function () {
				this.classList.add('active')
			})

			const secondElement = textblocks[a].children[1]
			textblocks[a].insertBefore(moreButton, secondElement)
		}
	}
}