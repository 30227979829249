/**
 * Animation. CSS transitions and a simple Interval wrapper.
 */

import { CSSNames, StyleNames } from './helpers'
import Easing from './easing'

const requestAnimFrame = window.requestAnimationFrame
	|| window.webkitRequestAnimationFrame
	|| window.msRequestAnimationFrame
	|| function (fn) { setTimeout(fn, 1000 / 60) }

const transitionEndEvents = ['transitionend', 'webkitTransitionEnd', 'msTransitionEnd']

// Animate with CSS transitions (preferred for DOM elements)
// Keep in mind: `transform` et al will be automatically translated to vendor specific names.
export function animate(obj, styles, properties, onDone) {
	requestAnimFrame(() => {
		const settings = properties || {}
		const delay = settings.delay || 0
		const duration = settings.duration || 1000
		const curve = settings.curve || Easing.cssCurves.linear
		const fn = onDone || function () {}

		const {transition} = StyleNames

		const preserve = obj.style[transition] // Preserve the current transition style.
		let animationIsDone = false // onDone() is called for EVERY transitioned property. 
		
		// Only add an `transitionend` event if one is actually given.		
		function done(event) {
			if (!animationIsDone) {
				animationIsDone = true

				transitionEndEvents.forEach((eventName) => {
					obj.removeEventListener(eventName, done)
				})

				fn.call(obj, event)
				obj.style[transition] = preserve
			}
		}

		// Apply the onDone event.
		transitionEndEvents.forEach((eventName) => {
			obj.addEventListener(eventName, done)
		})
		
		let transitionProperty = []

		for (const key in styles) {
			const property = CSSNames[key] || key
			transitionProperty.push(`${property} ${duration}ms ${curve} ${delay}ms`)
		}

		obj.style[transition] = transitionProperty.join(', ')

		// Now set the styles.
		for (const [key, value] of Object.entries(styles)) {
			const property = StyleNames[key] || key
			obj.style[property] = value
		}
	})
}


// Interval class.
export class Interval {
	constructor(properties, fn) {
		if (!fn) {
			throw Error('No function given to Interval.')
		}

		const settings = properties || {}
		const autostart = settings.autostart !== undefined ? settings.autostart : true
		this.delay = settings.delay || 0
		this.duration = settings.duration || 1000
		this.fps = settings.fps || 60
		this.loop = settings.loop || false

		this.fn = fn

		if (autostart) {
			this.start()
		}
	}

	restart() {
		this.stop()
		this.start()
		return this
	}

	start() {
		if (this.interval) {
			return this
		}
		
		const startTime = Date.now() + this.delay
		const frameDuration = 1000 / this.fps
		const step = frameDuration / this.duration
		let position = 0

		this.interval = setInterval(() => {
			const now = Date.now()

			if (startTime > now) {
				return
			}

			position = Math.min(position + step, 1)
			this.fn.call(this, position)

			if (position >= 1) {
				if (this.loop) {
					this.restart()
				}
				else {
					this.stop()
				}
			}
		}, frameDuration)

		return this
	}

	stop() {
		this.interval = clearInterval(this.interval)
		return this
	}	
}