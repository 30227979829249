import { animate, Interval } from '../lib/animation'
import Easing from '../lib/easing'

// Keep a list of *all* carousels.
export let carouselList = []

// The exporting class.
export class Carousel {

	static initializeAll() {
		const elements = [...document.getElementsByTagName('carousel')]
		
		for (const element of elements) {
			carouselList.push(new Carousel(element))
		}
	}
	
	constructor(element) {
		// First look whether there isn't already a carousel initialized.
		// If there is, return the initialized instance.
		for (const carousel of carouselList) {
			if (carousel.element === element) {
				return carousel
			}
		}

		this.element = element
		this.blocksHolder = this.element.querySelector('blocks')
		this.dotsHolder = this.element.querySelector('dots') || function () {
			let dotsHolder = document.createElement('dots')
			element.appendChild(dotsHolder)
			return dotsHolder
		}()

		this.currentIndex = 0
		this.intervalTime = 9000 // ms
		this.scrollOffset = 0.2
		this.transitionDuration = 1200 // ms

		this.setDots()
		this.play()

		carouselList.push(this)

		let blocks = [...this.blocksHolder.children]
		if (blocks.length > 0) {
			blocks[0].classList.add('visibleBlock')
		}
	}

	play() {
		this.stop() // Kill timeout first.
		this.queueNext()
		return this
	}

	queueNext() {
		this.timeout = setTimeout(this.next.bind(this), this.intervalTime)
		return this
	}

	next() {
		let nextIndex = this.currentIndex + 1
		if (nextIndex >= this.blocksHolder.childElementCount) {
			nextIndex = 0
		}

		this.show(nextIndex)

		if (this.timeout != undefined) {
			this.queueNext()
		}
		return this
	}

	show(index = 0) {
		let nextIndex = Math.clamp(index, 0, this.blocksHolder.childElementCount - 1)
		const currentEl = this.blocksHolder.children[this.currentIndex]
		const nextEl = this.blocksHolder.children[nextIndex]

		if (currentEl == nextEl) {
			return this.stop()
		}

		// Let's not mess around with the z-index. ;)
		// Animate the opacity depending on their order.
		if (nextIndex > this.currentIndex) {
			nextEl.style.opacity = 0
			nextEl.classList.add('visibleBlock');
			animate(nextEl, { opacity: 1 }, { duration: this.transitionDuration }, () => {
				currentEl.style.opacity = 0
				currentEl.classList.remove('visibleBlock');
			})
		}
		else {
			nextEl.style.opacity = 1
			nextEl.classList.add('visibleBlock');
			animate(currentEl, { opacity: 0 }, { duration: this.transitionDuration }, () => {
				currentEl.style.opacity = 0
				currentEl.classList.remove('visibleBlock');
			})
		}

		// The dots.
		for (let a = 0; a < this.dotsHolder.childElementCount; a++) {
			const fn = a == nextIndex ? 'add' : 'remove'
			this.dotsHolder.children[a].classList[fn]('current')
		}

		this.currentIndex = nextIndex
		return this
	}

	stop() {
		clearTimeout(this.timeout)
		delete this.timeout
		return this
	}

	driveAnimation(position) {
		
	}

	setOffset(y) {
		y = Math.max(y, 0)
		this.blocksHolder.style.transform = `translateY(${y * this.scrollOffset}px)`
		return this
	}

	setDots() {
		const count = this.blocksHolder.childElementCount

		if (count > 1) {
			for (let a = 0; a < count; a++) {
				let dot = document.createElement('dot')
				let index = a
				dot.addEventListener('click', (event) => {
					event.preventDefault()
					this.show(index)
					this.play()
					return this
				})

				this.dotsHolder.appendChild(dot)
				if (a == this.currentIndex) {
					dot.classList.add('current')
				}
			}
		}

		return this
	}
}