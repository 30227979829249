import './lib/polyfills'
import { parseQuery } from './lib/helpers'
import { scrollTo, scrollToElement } from './lib/scrollTo'
import { Carousel } from './components/carousel'
import { ClientList } from './components/clientlist'
import toggleMenu from './components/togglemenu'
import menuDropdown from './components/menudropdown'
import initializeLogoBlocks from './components/logosblock'
import initializeShare from './components/share'
import initializeAssessmentsFlowchart from './components/assessments'
import initializeSpiegelappPage from './components/spiegelapp'
import initializeMarquee from './components/marquee'
import initializeEventBlocks from './components/eventsblock'
import initializeScroll from './components/scroll'

const isMobile = () => innerWidth <= 1024

// Entry.
addEventListener('DOMContentLoaded', () => {
	Carousel.initializeAll()
	initializeMenu()
	initializeScroll()
	initializeSidemenu()
	toggleMenu()
	menuDropdown()

	initializeAssessmentsFlowchart()
	initializeSpiegelappPage()
	initializeDropdown()
	initializeLogoBlocks()
	initializeShare()

	initializeMarquee()
	initializeEventBlocks()

	initializeFAQBlock()
	initializeFAQScroll()

	removeAttribute()
	morePosts()

	$('.children-hidden').parent().children('.dropdown').remove();

	if (/(Android|iPod|iPhone|iPad|iOS)/gi.test(navigator.userAgent)) {
		document.body.classList.add('mobile')
	}

	// define elements and assign id to them
	if (document.querySelector('#krachtbronnenblock .secondcolumn')) {
		const bulletFirst = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[0];
		bulletFirst.setAttribute('id', 'orglink');
		const bulletSecond = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[1];
		bulletSecond.setAttribute('id', 'translink');
		const bulletThird = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[2];
		bulletThird.setAttribute('id', 'talentlink');
		const bulletFourth = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[3];
		bulletFourth.setAttribute('id', 'assesslink');
		const bulletFifth = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[4];
		bulletFifth.setAttribute('id', 'trainlink');
		const bulletSixth = document.querySelectorAll('#krachtbronnenblock .secondcolumn p a')[5];
		bulletSixth.setAttribute('id', 'coachlink');
		//scrollToDivSmooth()
	}
})

addEventListener('load', () => {
	initializeForms()
})

// Show more blog posts
function morePosts() {
	if (document.getElementById('sterkeverhalen')) {
		const moreButton = document.getElementById('moreposts');
		if (!moreButton) {
			return;
		}

		const newsBlock = document.querySelector('.newsblock')
		const newsBlockGroups = [...document.querySelectorAll('.newsblock > div')]
		let currentVisible = 0

		function showGroups() {
			currentVisible++

			const maxCount = Math.min(currentVisible, newsBlockGroups.length)
			let totalHeight = 0
			for (let a = 0; a < maxCount; a++) {
				totalHeight += newsBlockGroups[a].offsetHeight
			}

			newsBlock.style.height = totalHeight + 'px'

			if (currentVisible == newsBlockGroups.length) {
				moreButton.parentNode.removeChild(moreButton)
			}
		}

		showGroups()

		moreButton.addEventListener('click', (event) => {
			event.preventDefault()
			showGroups()
		})
	}
}

// Forms.
function initializeForms() {
	// Set the contact form's message if the query `message` exists.
	const queries = parseQuery(location.search)
	const textarea = document.querySelector('form textarea')
	const firstInput = document.querySelector('form input')

	if (textarea && queries.message) {
		textarea.value = queries.message.replace(/\+(?!C)/g, ' ')

		if (firstInput) {
			console.log(firstInput)
		}
	}
}

// Remove href attribute from links on contact page for route
function removeAttribute() {
	if (document.getElementById('contact')) {
		const aFirst = document.querySelectorAll('#contact .contactinfo ul li a')[0];
		const aSecond = document.querySelectorAll('#contact .contactinfo ul li a')[1];
		const aThird = document.querySelectorAll('#contact .contactinfo ul li a')[2];
		const aFourth = document.querySelectorAll('#contact .contactinfo ul li a')[3];

		aFirst.removeAttribute('href');
		aSecond.removeAttribute('href');
		aThird.removeAttribute('href');
		aFourth.removeAttribute('href');
	}
}

// Dropdown for contact route
function initializeDropdown() {
	let lis = document.querySelectorAll('#contact .contactinfo ul > li');
	lis = [...lis].filter(li => {
		return li.querySelector('ul')
	})

	for (const li of lis) {
		li.addEventListener('click', function (event) {
			this.classList.toggle('minus')
		})
	}
}

function initializeMenu() {
	// Inactive buttons.
	const inactives = [...document.querySelectorAll('li a.inactive')]
	for (const element of inactives) {
		element.addEventListener('click', (event) => {
			event.preventDefault()
		})
	}

	// External links.
	const externals = [...document.querySelectorAll('li a.external')]
	for (const external of externals) {
		external.target = '_blank'
	}

	const menuElement = document.getElementById('menu')
	const menuHeight = menuElement.offsetHeight
	const hamburger = document.getElementById('hamburger')
	const hero = document.getElementById('hero')
	const logo = document.getElementById('hclogo')
	const languageBlock = document.getElementById('languageblock')

	// Toggling.
	hamburger.addEventListener('click', (event) => {
		event.preventDefault()
		logo.classList.toggle('hidden')
		menuElement.classList.toggle('open')
		languageBlock.classList.toggle('zindex')
	})
}

function initializeSidemenu() {
	const contactButton = document.querySelector('div.sidemenu a.contact')
	if (contactButton) {
		contactButton.addEventListener('click', (event) => {
			event.preventDefault()
			const section = document.querySelector('section.form')
			if (section) {
				scrollToElement(section, {
					y: -100
				})
			}
		})
	}
}

function initializeFAQBlock() {
	const expandables = document.querySelectorAll('div.faqblock div.faq-expandable');

	if (!expandables[0]) return

	const closedHeight = (element) => element.offsetHeight

	const toggleExpandable = (expandable) => {
		const title = expandable.querySelector('div.summary p')
		const classList = expandable.classList
		const isOpen = expandable.classList.contains('open')

		isOpen ? classList.remove('open') : classList.add('open')
		expandable.style.height = `${isOpen ? closedHeight(title) : expandable.originalHeight}px`
	}

	for (let i = 0; i < expandables.length; i++) {
		const expandable = expandables[i]
		const summary = expandable.querySelector('div.summary')
		const title = summary.querySelector('p')

		expandable.originalHeight = expandable.offsetHeight
		expandable.style.height = `${closedHeight(title)}px`
		summary.addEventListener('click', (e) => toggleExpandable(expandable))
	}

	window.addEventListener('resize', (e) => {
		for (let i = 0; i < expandables.length; i++) {
			const expandable = expandables[i]

			if (!expandable.classList.contains('open')) {
				const title = expandable.querySelector('div.summary p')
				expandable.style.height = `${closedHeight(title)}px`
			}
		}
	})
}

function initializeFAQScroll() {
	const scrollables = document.querySelectorAll('.faq-intro div.textblock ul li')
	if (!scrollables[0]) return

	const onClick = (scrollable) => {
		const query = scrollable.classList[0]
		const target = document.getElementById(query)
		scrollToElement(target, {
			y: -110
		})
	}

	for (let i = 0; i < scrollables.length; i++) {
		const scrollable = scrollables[i];
		scrollable.addEventListener('click', (e) => onClick(scrollable))
	}
}