export default function initializeSocialMediaSharing() {
	const facebookButton = document.getElementById('share-facebook')
	if (facebookButton) {
		facebookButton.addEventListener('click', () => {
			const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(location.href)
			open(url, 'facebook-share-dialog', 'width=626,height=436')
		})
	}

	const linkedinButton = document.getElementById('share-linkedin')
	if (linkedinButton) {
		linkedinButton.addEventListener('click', () => {
			const url = 'http://www.linkedin.com/shareArticle?url=' + encodeURIComponent(location.href)
			open(url, 'twitter-share-dialog', 'width=826,height=436')
		})
	}

	const twitterButton = document.getElementById('share-twitter')
	if (twitterButton) {
		twitterButton.addEventListener('click', () => {
			const url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(location.href)
			open(url, 'linkedin-share-dialog', 'width=626,height=464')
		})
	}
}