export default function toggleMenu() {
	let toggleIcon = document.getElementsByClassName('toggle');

	for (let i = 0; i < toggleIcon.length; i++) {
		toggleIcon[i].addEventListener('click', function(event){
			const parent = this.parentNode.parentNode
			parent.className = parent.className ? '' : 'openmenu'
			event.preventDefault()
			event.stopPropagation()
		});
	}	
	
}
