import { Interval } from './animation'
import Easing from './easing'
import { offsetOfElement } from './helpers'

export const scrollTo = function scrollTo() {
	let start = { x: 0, y: 0 }
	let end = { x: 0, y: 0 }

	const interval = new Interval({
		autostart: false,
	}, (position) => {
		const easing = Easing.calc(position, Easing.InOut, Easing.Quint)
		window.scrollTo(
			Math.mix(start.x, end.x, easing),
			Math.mix(start.y, end.y, easing)
		)
	})

	return function (x, y, duration = 1000) {
		start.x = window.scrollX || window.pageXOffset
		start.y = window.scrollY || window.pageYOffset
		end = { x, y }

		interval.duration = duration
		interval.stop()
		interval.start()
	}
}()

export function scrollToElement(element, offset = {}) {
	const { x = 0, y = 0 } = offset
	const rect = offsetOfElement(element)
	scrollTo(rect.offsetLeft + x, rect.offsetTop + y)
}