import { scrollToElement } from '../lib/scrollTo'

//handle all animated scrolling here
export default function initializeScroll() {
    const elements = [...document.querySelectorAll('div.sidemenu-item')] // div not a!
    if (!elements.length > 0) return

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i]

        element.addEventListener('click', (e) => {
            e.preventDefault()

            const target = document.querySelector(`section#${element.dataset.target}`)
            target ? scrollToElement(target, {x: 0, y: -200}) : console.error('no target found')
        })
    }
}