import { Interval } from '../lib/animation'
import { offsetOfElement } from '../lib/helpers'
import { scrollTo, scrollToElement } from '../lib/scrollTo'

export default function initializeAssessments() {
	// Flowchart.
	const parent = document.querySelector('#assessments-flow')
	if (parent) {
		new Assessments(parent)
	}

	// Urls.
	const assessmentAnchors = document.querySelectorAll('div.assessment-item a')
	for (let a = 0; a < assessmentAnchors.length; a++) {
		assessmentAnchors[a].addEventListener('click', (event) => {
			event.preventDefault()
			const formSection = document.querySelector('section.form')
			scrollToElement(formSection, {
				y: -100
			})
		})
	}
}

const xmlns = 'http://www.w3.org/2000/svg'

function rectOfElement(element) {
	return {
		x: element.offsetLeft,
		y: element.offsetTop,
		width: element.offsetWidth,
		height: element.offsetHeight
	}
}

class Assessments {
	constructor(parent) {
		this.parent = parent
		this.rows = []
		this.lines = new LinesCanvas(this.parent.querySelector('svg'))
		this.result = []

		// Initialize all rows.
		const rows = this.parent.querySelectorAll('div.row')
		for (let a = 0; a < rows.length; a++) {
			const rowOptions = []
			const options = rows[a].querySelectorAll('a')

			for (let b = 0; b < options.length; b++) {
				const option = options[b]
				option.row = a

				option.addEventListener('click', this.onOptionClick(option))

				rowOptions.push(option)
			}

			this.rows.push(rowOptions)
		}

		// Steal all the 'results' that are placed via the template/CMS.
		const results = document.querySelectorAll('section div.textblock.result')
		const resultsWrapper = this.parent.querySelector('div.results')
		for (let a = 0; a < results.length; a++) {
			const previousParent = results[a].parentElement.parentElement
			previousParent.parentElement.removeChild(previousParent)
			
			// Insert 'Resultaat: ' infront of text node.
			const title = results[a].querySelector('h2')
			const b = document.createElement('b')
			title.insertBefore(b, title.childNodes[title.childNodes.length - 1])

			resultsWrapper.appendChild(results[a])
		}

		addEventListener('resize', this.onResize)
		this.onResize()
	}

	onOptionClick(option) {
		return (event) => {
			this.result[option.row] = option.dataset.name
			this.result.splice(option.row + 1)

			const currentRow = this.parent.querySelector('div.row-' + (option.row + 1))

			// Check if there's a result. If there is, we're done here.
			// Otherwise continue to the next set of options.
			const result = this.setResult()
			if (result) {
				const rect = offsetOfElement(currentRow)
				scrollTo(0, rect.offsetTop + currentRow.offsetHeight - 60)

				this.selectOption(option)
				this.lines.setLine(option.row, option, result)
				this.onResize()
			}
			else {
				// Scroll to next row.
				const nextRow = this.parent.querySelector('div.row-' + (option.row + 2))
				if (nextRow) {
					// Select the current item.
					this.selectOption(option)

					currentRow.classList.add('selected')

					// Only show related options.
					for (const opt of this.rows[option.row + 1]) {
						const groups = (opt.dataset.groups?.split(' ') || []).filter(g => g)

						// Names in `groups` *have* to be present in `this.result`.
						if (groups.length > 0) {
							const show = groups.every(name => this.result.includes(name))
							if (!show) {
								opt.classList.add('hidden')
							}
							else {
								opt.classList.remove('hidden')
							}
						}
					}

					// Scroll to next row (and draw a line).
					const nextRowTitle = nextRow.querySelector('h3')
					scrollTo(0, offsetOfElement(nextRow).offsetTop - 200)

					this.lines.setLine(option.row, option, nextRowTitle)
					this.onResize()
				}
			}
		}
	}

	selectOption(option) {
		for (let a = option.row; a < this.rows.length; a++) {
			for (const opt of this.rows[a]) {
				if (opt === option) {
					opt.classList.add('selected')
				}
				else {
					opt.classList.remove('selected')
				}
			}
		}

		// Hide all subsequent rows.
		for (let a = option.row + 1; a < 20; a++) {
			const subsequentRow = this.parent.querySelector('div.row-' + a)
			if (subsequentRow) {
				subsequentRow.classList.remove('selected')
			}
			else {
				break
			}
		}
	}

	setResult = () => {
		// Deactivate previous result, if visible.
		const previousResult = this.parent.querySelector('.result.visible')
		previousResult?.classList.remove('visible')

		const resultClassname = this.result.join('-')
		const newResult = this.parent.querySelector('.result.' + resultClassname)
		if (newResult) {
			newResult.classList.add('visible')
			this.parent.querySelector('div.results').classList.add('visible')
		}
		else {
			this.parent.querySelector('div.results').classList.remove('visible')
		}

		return newResult
	}

	onResize = () => {
		this.lines.resize()
	}
}

class LinesCanvas {
	constructor(svg) {
		this.svg = svg
		this.lines = []
	}

	setLine(index, from, to) {
		// Delete consecutive lines.
		for (let a = index; a < this.lines.length; a++) {
			if (this.lines[a]) {
				this.lines[a].removeFromParent()
			}
		}
		this.lines.splice(index, Math.max(this.lines.length - index - 1, 0))

		const line = new Line({
			from, to,
			parent: this.svg,
		})
		this.lines[index] = line
	}

	resize() {
		this.svg.setAttributeNS(null, 'viewBox', `0 0 ${this.svg.clientWidth} ${this.svg.clientHeight}`)

		for (const line of this.lines) {
			if (line) {
				line.render()
			}
		}
	}
}

class Line {
	constructor({ from, to, parent }) {
		this.fromElement = from
		this.toElement = to
		this.parent = parent
		this.length = 0

		this.element = document.createElementNS(xmlns, 'path')
		this.parent.appendChild(this.element)

		this.interval = new Interval({ duration: 700 }, (position) => {
			const length = (this.length || 1e6)
			this.element.style.strokeDasharray = length || 1e6
			this.element.style.strokeDashoffset = (1 - position) * length
		})

		this.interval.fn(0) // Initialize on first frame.

		this.render()
	}

	render() {
		const startRect = rectOfElement(this.fromElement)
		const endRect = rectOfElement(this.toElement)

		/**
		 *  A
		 *  |
		 *  B - C
		 *      |
		 *      D
		 */
		const start = {
			x: startRect.x + (startRect.width / 2),
			y: startRect.y + (startRect.height)
		}

		const end = {
			x: endRect.x + (endRect.width / 2),
			y: endRect.y + (endRect.height / 2)
		}

		const A = ['M', start.x, start.y]
		const B = ['L', start.x, end.y - 40]
		const C = ['L', end.x, B[2]]
		const D = ['L', end.x, end.y]

		const d = [A, B, C, D]
			.map(([command, x, y]) => `${command} ${Math.round(x)},${Math.round(y)}`)
			.join(' ')
		this.element.setAttributeNS(null, 'd', d)

		// Calculate the length of the line.
		this.length = Math.abs(end.x - start.x) + Math.abs(end.y - start.y)
	}

	removeFromParent() {
		this.interval.stop()
		this.parent.removeChild(this.element)
	}
}