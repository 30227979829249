Math.clamp = Math.clamp || function clamp(val, min, max) {
	return +Math.min(Math.max(+val, min), max)
}

Math.mix = Math.mix || function mix(a, b, c) {
    return a + ((b - a) * c)
}

Object.entries = Object.entries || function entries(obj) {
	return Object.keys(obj).map((key) => [key, obj[key]])
}