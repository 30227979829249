import { offsetOfElement } from '../lib/helpers'

export default function initializeLogosBlock() {
	const logosBlock = document.querySelector('.logosblock')
	if (!logosBlock) {
		return
	}

	function scroll(event) {
		const scrollY = window.scrollY || document.body.scrollTop || window.pageYOffset || 0
		const scrollEnd = scrollY + innerHeight

		const offsetTop = offsetOfElement(logosBlock).offsetTop
		const offsetEnd = offsetTop + logosBlock.offsetHeight

		if (scrollEnd >= offsetTop && scrollY <= offsetEnd) {
			logosBlock.classList.add('visible')
			removeEventListener('scroll', scroll)
		}
	}

	// Perform scroll and attach listener.
	scroll()
	addEventListener('scroll', scroll)
}