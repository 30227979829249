export default function menuDropdown() {
	const icon = document.getElementsByClassName('dropdown');

	for (let i = 0; i < icon.length; i++) {
		const parent = icon[i].parentNode;

		// If clicked on icon, toggle the dropdown of the div
		icon[i].addEventListener('click', function (event) {
			event.preventDefault()
			event.stopPropagation()
			closeAll(this.parentNode)
			this.parentNode.classList.toggle('divToggle');
		});
	}

	window.addEventListener('click', closeAll)

	function closeAll(except) {
		let allOpen = document.getElementsByClassName('divToggle')
		for (let a = 0; a < allOpen.length; a++) {
			if (except !== allOpen[a]) {
				allOpen[a].classList.remove('divToggle')
			}
		}
	}
}
