export default function initializeMarquee() {
    const scrollElement = document.querySelector('div.scroll-container:not(.duplicate)')
    if (!scrollElement) return

    const items = [...scrollElement.querySelectorAll('.client')]
    if (!items) return

    // <content> width
    const windowWidth = scrollElement.parentElement.parentElement.parentElement.offsetWidth

    const duplicateScroll = document.querySelector('div.scroll-container.duplicate')
    const duplicateItems = [...duplicateScroll.querySelectorAll('.client')]

    // startAt('S')

    duplicateScroll.style.transform = 'translateX(' + windowWidth + 'px)'

    let marginLeft = getMargin(items[0])

    let itemWidth = items[0].offsetWidth + (marginLeft * 2)
    let maxLength = (itemWidth * items.length)
    const visibleItems = windowWidth / itemWidth

    setInterval(_ => {
        let max = -maxLength + (windowWidth)
        let current = getTranslateX(scrollElement)
        let duplicate = getTranslateX(duplicateScroll)
        var shown = false

        if ((current < max || duplicate < windowWidth) && duplicate > -windowWidth) {
            setVisible()
            let x = duplicate - 1
            duplicateScroll.style.transform = 'translateX(' + x + 'px)'
            shown
        } else {
            setVisible(false)
            duplicateScroll.style.transform = 'translateX(' + windowWidth + 'px)'
        }

        if (current <= (-maxLength)) {
            scrollElement.style.transform = 'translateX(0%)'
            return
        }
        let n = current - 1
        scrollElement.style.transform = 'translateX(' + n + 'px)'
    }, 10)


    // Returns values of computed translateX.
    const getTranslateX = (element) => {
        var style = window.getComputedStyle(element)
        var matrix = new WebKitCSSMatrix(style.webkitTransform)
        return matrix.m41
    }

    // Hides and shows the duplicate when necessary.
    const setVisible = (visible = true) => {
        if (visible) {
            for (let i = 0; i < visibleItems; i++) {
                duplicateItems[i].style.display = 'block'
                items[i].style.visibility = 'hidden'
            }
        } else {
            for (let i = 0; i < duplicateItems.length; i++) {
                duplicateItems[i].style.display = 'none'
                if (items[i]) items[i].style.visibility = 'visible'
            }
        }
    }
}

// Finds the first item with the given letter
// and sets the scroller to its position.
function startAt(letter) {
    var iterationCounter = 0

    const scrollElement = document.querySelector('div.scroll-container:not(.duplicate)')
    const items = [...scrollElement.querySelectorAll('.client')]
    const itemWithLetter = [...scrollElement.querySelectorAll('.client p')]

    let marginLeft = getMargin(items[0])
    let itemWidth = items[0].offsetWidth + (marginLeft * 2)

    for (let i = 0; i < itemWithLetter.length; i++) {
        const item = itemWithLetter[i]

        iterationCounter++

        if (item.innerHTML.charAt(0).toLowerCase() === letter.toLowerCase()) {
            let scrollTo = itemWidth * iterationCounter
            scrollElement.style.transform = `translateX(-${scrollTo}px)`
            break
        }
    }
}

function getMargin(firstItem) {
    let style = window.getComputedStyle(firstItem)
    let marginLeft = parseInt(style.marginLeft)
    return marginLeft   
}