/**
 * Helper functions.
 */

const isMobile = /(Android|iPhone|iPod|iPad)/gi.test(navigator.userAgent)

/**
 * CSS and Style names for transitions and transform.
 * Yay for old browsers / webviews.
 */
let CSSNames = {}
let StyleNames = {}

addEventListener('DOMContentLoaded', () => {
	// Turns 'transform-origin' into 'transformOrigin'.
	function styleName(vendor, property) {
		property = property.split('-').map((word, i) => {
			if (i > 0 || vendor.length) {
				word = word.substr(0, 1).toUpperCase() + word.substr(1, word.length - 1)
			}
			return word
		}).join('')

		return vendor + property
	}

	function cssName(vendor, property) {
		if (vendor.length) {
			vendor = '-' + vendor + '-'
		}
		return vendor + property
	}

	const vendors = ['', 'webkit', 'ms']
	const properties = ['animation', 'transform', 'transition', 'transform-origin']

	// Find the names for 'transition'.
	for (const property of properties) {
		for (const vendor of vendors) {
			let style = styleName(vendor, property)
			let css = cssName(vendor, property)

			if (style in document.body.style) {
				StyleNames[property] = style
				CSSNames[property] = css
				break
			}
		}
	}
})

export { CSSNames, isMobile, StyleNames }

export function offsetOfElement(element, root = document.body) {
	let { offsetLeft, offsetTop } = element

	while ((element = element.parentElement) && element != root) {
		offsetLeft += element.offsetLeft || 0
		offsetTop += element.offsetTop || 0
	}

	return {
		x: offsetLeft,
		y: offsetTop,
		width: element.offsetWidth,
		height: element.offsetHeight,
		offsetLeft,
		offsetTop,
	}
}

export function parseQuery(query) {
	let values = {}

	query.replace(/&?([^?#=]+)=?([^?=&]+)?/g, (_, p1, p2) => {
		values[p1] = p2 === undefined ? true
			: !isNaN(parseInt(p2, 10)) ? (p2 | 0)
				: decodeURIComponent(p2)
	})

	return values
}